/**
 * 小游戏
 *
 */

import request from "@/utils/request";

// 展示
export const xingGe = (data) => {
  return request({
    method: "post",
    url: "/juheApi/xing-ge",
    data,
  });
};
