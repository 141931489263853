<template>
  <div id="MBTI">
    <el-button class="reustBtn" icon="el-icon-s-home" @click="toHemo">返回首页</el-button>
    <div class="quiz-container">
      <h1 class="MBTITitle">性格测试</h1>
      <div class="stepBox">
        <el-steps :active="stepActive" finish-status="success" align-center>
          <el-step>
            <template slot="title"> 描述题 </template>
            <template slot="description"> {{ currentQuestionIndex }}/{{ questions.length - 1 }} </template>
          </el-step>
          <el-step>
            <template slot="title"> 提交测试 </template>
            <template slot="description"> </template>
          </el-step>
        </el-steps>
      </div>
      <div class="progressBox">
        <el-progress :text-inside="true" :stroke-width="16" :show-text="false" :percentage="getProportion"></el-progress>
      </div>
      <el-card v-if="!submitShow" class="quiz-card">
        <div v-if="startBool">
          <div>
            <p class="question-text">
              {{ questions[currentQuestionIndex].text }}:
            </p>
            <!-- <el-radio-group v-model="selectedOption">
            <el-radio v-for="(option, index) in questions[currentQuestionIndex].options" :key="index" :label="index">
              {{ option }}
            </el-radio>
          </el-radio-group> -->
            <div class="questionList" v-for="(option, index) in questions[currentQuestionIndex].options" :key="index">
              <div class="questionItem" :class="{
                active: questions[currentQuestionIndex].answerIndex == index,
              }" @click="nextQuestion(option, index)">
                {{ option }}
              </div>
            </div>
          </div>
          <div class="button-group">
            <!-- <p>{{ questions[currentQuestionIndex].answerIndex }}</p> -->
            <el-button @click="lastQuestion" v-if="currentQuestionIndex != 0">上一题</el-button>
            <el-button @click="currentQuestionIndex++" v-if="currentQuestionIndex != questions.length - 1 &&
              questions[currentQuestionIndex].answerIndex !== undefined
              ">下一题</el-button>
            <!-- <el-button v-if="currentQuestionIndex === questions.length - 1 && submitShow" type="primary"
            @click="submitEvaluation">评测提交</el-button> -->
            <!-- <p>当前索引{{ currentQuestionIndex }}</p> -->
            <!-- <p>问题长度{{ questions.length - 1 }}</p> -->
          </div>
        </div>
        <div v-else class="flexCenter">
          <el-empty description="暂无题目"></el-empty>
        </div>
      </el-card>
      <el-card v-else class="result-card">
        <el-result icon="success" title="" subTitle="你已完成所有测试题,可提交答案领取测试结果~">
          <template slot="extra">
            <!-- <el-button type="primary" size="medium">返回</el-button> -->
          </template>
        </el-result>
        <div class="btnBox">
          <el-button type="info" @click="resetAnswer">重新答题</el-button>
          <el-button type="primary" @click="submitAnswer">评测提交</el-button>
        </div>
      </el-card>
    </div>

    <!-- 获取答案并展示 -->
    <div class="model" @click.stop="" v-if="showAnswerBool">
      <el-card class="answer-card">
        <h1 class="title">MBTI人格分析</h1>
        <div class="content">
          <div class="outline">
            经测试题分析,你的人格类型为<span style="font-weight: bold;font-size: 1.1rem;"> {{ textData.occupation }} </span>(<span
              class="alphabetClass" @click="toBaidu(textData.alphabet)">{{ textData.alphabet }}</span>),英文名 {{
                textData.vocabulary }}
          </div>
          <div>
            <div class="contentTitle">
              性格概述:
            </div>
            <div class="textDesc" v-for="v in textData.summarize">{{ v }}</div>
          </div>
          <div>
            <div class="contentTitle">
              性格描述:
            </div>
            <div class="textDesc" v-for="v in textData.desc">{{ v }}</div>
          </div>
          <div>
            <div class="contentTitle">
              性格特点:
            </div>
            <div v-for="v in textData.characteristic">
              <div class="characteristicTitle">
                {{ v.title }}
              </div>
              <div v-for="item in  v.desc" class="textDesc">
                {{ item }}
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>

    <!-- 模板层 -->
    <div class="model" @click.stop="" v-if="showModel">
      <el-button class="getBtn" plain @click="fetchQuestions">开始你的性格测试吧~</el-button>
    </div>
  </div>
</template>


<script>
import { xingGe } from "@/api/newGame";

export default {
  data() {
    return {
      showAnswerBool: false,
      startBool: false,
      submitShow: false,
      showModel: true,
      questions: [], // 保存题目数组
      currentQuestionIndex: 0, // 当前题目的索引
      selectedOption: null, // 保存用户选择的选项
      showResults: false, // 控制是否显示评测结果
      textData: {}
    };
  },
  watch: {
    currentQuestionIndex: function (newvalue, oldvalue2) {
      // 通过value参数(参数名可以自己命名)接受侦听数据的最新值
      let pro = (this.currentQuestionIndex / (this.questions.length - 1) * 100).toFixed(0)
      if (pro == 100) {
        this.stepActive = 2
        // console.log("进度为100");
      } else {
        // console.log("进度不足100");
        this.stepActive = 1
      }
    }
  },
  computed: {
    getProportion() {
      let pro = (this.currentQuestionIndex / (this.questions.length - 1) * 100).toFixed(0)
      return pro
    },
    // getProportionStatus() {
    //   if (this.getProportion <= 20) {
    //     return 'exception'
    //   } else if (this.getProportion > 20 && this.getProportion <= 40) {
    //     return 'warning'
    //   } else if (this.getProportion > 40) {
    //     return ''
    //   } else if (this.getProportion == 100) {
    //     return 'success'
    //   }
    // }
  },
  created() {
    this.$nextTick(() => {
      console.log("this.textData", this.textData);
      // 在组件挂载时获取题目数据
      // this.fetchQuestions();
    });
  },
  methods: {
    toHemo() {
      this.$router.push("/");
    },
    toBaidu(name) {
      let url = 'https://www.baidu.com/s?wd=' + name
      window.open(url, '_blank')
    },
    submitAnswer() {

      let params = { answers: '' }
      let arr = this.questions.map((v) => {
        return v.answerOption[v.answerIndex]
      })
      params.answers = arr.join(',')
      xingGe(params)
        .then((res) => {
          if (res.data.statusCode == '00000') {
            this.textData = res.data.data
            this.showAnswerBool = true
            this.$message.success(res.data.Message || '获取答案成功');
          } else {
            this.$message.error(res.data.Message || '获取题目失败');
          }
        })
        .catch((err) => { });
    },
    resetAnswer() {
      this.submitShow = false
    },
    // 获取题目数据的方法，可以根据实际情况调用后端接口
    fetchQuestions() {

      // 假设后端接口返回一个题目数组
      // 这里使用一个示例数组，实际应替换为从后端获取的数据
      // this.questions = [
      //   { id: 1, text: '问题 1', options: ['选项A', '选项B', '选项C'] },
      //   { id: 2, text: '问题 2', options: ['选项X', '选项Y', '选项Z'] },
      //   { id: 3, text: '问题 3', options: ['选项X', '选项Y', '选项Z'] },
      //   { id: 4, text: '问题 4', options: ['选项X', '选项Y', '选项Z'] },
      //   { id: 5, text: '问题 5', options: ['选项X', '选项Y', '选项Z'] },
      //   { id: 6, text: '问题 6', options: ['选项X', '选项Y', '选项Z'] },
      //   // ... 其他题目
      // ];

      let params = { level: 12 }

      xingGe(params)
        .then((res) => {
          if (res.data.statusCode == '00000') {
            // console.log("res", res.data.data);
            // console.log("res", res.data.data.result);
            this.questions = res.data.data.map((v, index) => {
              return {
                id: index,
                text: v.q,
                options: [v.a, v.b],
                answerOption: [v.ia, v.ib]
              }
            })
            this.showModel = false
            this.startBool = true
            // this.$message.success(res.data.Message || '获取题目成功');
          } else {
            this.$message.error(res.data.Message || '获取题目失败');
          }
        })
        .catch((err) => { });

    },

    // 上一题
    lastQuestion() {
      this.submitShow = false
      this.currentQuestionIndex--;
    },
    // 切换到下一题的方法
    nextQuestion(option, index) {
      //// console.log("option", option);
      if (this.currentQuestionIndex == this.questions.length - 1) {
        this.questions[this.currentQuestionIndex].answerIndex = index;
        this.submitShow = true
        // console.log("显示提交", this.questions);
      } else {
        this.questions[this.currentQuestionIndex].answerIndex = index;
        this.currentQuestionIndex++;
      }
      // console.log("this.currentQuestionIndex ", this.currentQuestionIndex);
      // } else if (this.currentQuestionIndex < this.questions.length-1 - 1) {

      // this.selectedOption = null; // 清空用户选择的选项
      // }
    },
    // 提交评测的方法
    submitEvaluation() {
      // 处理评测提交逻辑，可以根据实际需求发送数据到后端
      // 这里简单地将结果输出到控制台
      //  console.log(
      //     "评测结果:",
      //     this.questions.map((q) => ({
      //       id: q.id,
      //       selectedOption: q.selectedOption,
      //     }))
      //   );
      this.showResults = true;
    },
  },
  mounted() { },
};
</script>


<style lang="less" scoped>
.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.model {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.6);

  .getBtn {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3.3rem;
    padding: 3rem;
  }
}

/deep/.progressBox {
  width: 50rem;
  padding: 5rem;
  padding-top: 1rem;
  box-sizing: border-box;
  border-radius: 25px;
  background-color: #DFEED7;
  margin-bottom: -4rem;

  .el-progress-bar__outer {
    background-color: #fff;
  }
}

#MBTI {
  width: 100%;
  height: 100%;
}

.reustBtn {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
}

.answer-card {
  position: absolute;
  width: 40rem;
  height: 90%;
  margin: 1rem 0;
  overflow-y: hidden;
  padding-top: 4.3rem;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("../../assets/images/answerBg.jpg");
  z-index: 9;
  left: 50%;
  transform: translateX(-50%);

  /deep/ .el-card__body {
    height: 100%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;

    .title {
      margin-left: 7rem;
    }

    .content {
      flex: 1;
      overflow-y: auto;
      text-align: justify;

      .characteristicTitle {
        color: #eb7350;
        font-weight: bold;
        margin: 0.5rem 0;
      }

      .textDesc {
        color: #333;
        line-height: 1.6rem;
        text-indent: 2rem;
        text-shadow: 0rem 0rem 1.5rem #999;
      }

      .outline {
        .alphabetClass {
          color: skyblue;
          font-size: 1.1rem;
          cursor: pointer;
          text-decoration: underline;
        }
      }

      .contentTitle {
        font-weight: bold;
        color: rgb(102, 102, 102);
        letter-spacing: normal;
        margin: 2rem 0;
      }
    }

    .content::-webkit-scrollbar {
      display: none;
    }

  }
}

.quiz-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("../../assets/images/MBTIBG.jpg");

  .MBTITitle {
    // color: #fff;
    font-size: 3rem;
    font-family: "微软雅黑";
    margin-bottom: 3rem;
  }
}

.quiz-card {
  min-height: 30rem;
  width: 50rem;
  /* 设置卡片的宽度 */
  padding: 2rem;
  padding-top: 0.4rem;
  box-sizing: border-box;
  border-radius: 25px;

  // text-align: center;
  .questionList {
    display: flex;
    flex-direction: column;

    .questionItem {
      cursor: pointer;
      margin: 1rem 0;
      padding: 1rem 2rem;
      box-sizing: border-box;
      font-size: 1.6rem;
      border-radius: 1rem;
      background-color: #f1f0f5;
    }

    .questionItem:active {
      color: #fff;
      background-color: #04be02 !important;
    }

    .active {
      color: #fff;
      background-color: #04be02 !important;
    }

    .questionItem:hover {
      opacity: 0.8;
    }
  }
}

.stepBox {
  width: 50rem;
}

.question-text {
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.button-group {
  margin-top: 2rem;
}

.result-card {
  min-height: 30rem;
  width: 50rem;
  padding: 2rem;
  padding-top: 0.4rem;
  box-sizing: border-box;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;


  .btnBox {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
</style>

